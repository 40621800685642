var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "background" }, [
    _c("div", { staticClass: "d-flex flex-row" }, [
      _c("div", { staticClass: "d-flex flex-column" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-row" },
          [
            _c(
              "v-card",
              { staticClass: "card", staticStyle: { "border-radius": "15px" } },
              [
                _c("div", { staticClass: "d-flex flex-column mb-0 pb-0 " }, [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center mt-4" },
                    [
                      _c("v-img", {
                        staticClass: "image",
                        attrs: { src: "/images/Favicon.png" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      _c("P", { staticClass: "font-title mb-0" }, [
                        _vm._v(" Welcome to "),
                      ]),
                      _c("p", { staticClass: "font-title mt-0 pt-0" }, [
                        _vm._v(
                          " TaxPOD Vision " +
                            _vm._s(_vm.platform == "dev" ? "Dev" : "") +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-0 pt-0", staticStyle: { width: "80%" } },
                  [
                    _c("AError", { attrs: { api: _vm.api } }),
                    _c(
                      "v-form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.fetch()
                          },
                        },
                      },
                      [
                        _c("div", {}, [
                          _c("p", { staticClass: "font-subtitle mb-0" }, [
                            _vm._v(" Email "),
                          ]),
                          _c("div", {}, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.email,
                                  expression: "form.email",
                                },
                              ],
                              ref: "email",
                              staticClass: "input-field px-3",
                              staticStyle: { height: "40px" },
                              attrs: {
                                placeholder: "",
                                required: "",
                                rules: _vm.rules,
                              },
                              domProps: { value: _vm.form.email },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.fetch.apply(null, arguments)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form,
                                    "email",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", {}, [
                          _c("p", { staticClass: "font-subtitle mb-0" }, [
                            _vm._v(" Password "),
                          ]),
                          (_vm.showPassword ? "text" : "password") ===
                          "checkbox"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.password,
                                    expression: "form.password",
                                  },
                                ],
                                ref: "password",
                                staticClass: "input-field px-3",
                                staticStyle: { height: "40px" },
                                attrs: {
                                  "append-icon": _vm.showPassword
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  required: "",
                                  placeholder: "",
                                  rules: _vm.rules,
                                  type: "checkbox",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.form.password)
                                    ? _vm._i(_vm.form.password, null) > -1
                                    : _vm.form.password,
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.fetch.apply(null, arguments)
                                  },
                                  change: function ($event) {
                                    var $$a = _vm.form.password,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.form,
                                            "password",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.form,
                                            "password",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.form, "password", $$c)
                                    }
                                  },
                                },
                              })
                            : (_vm.showPassword ? "text" : "password") ===
                              "radio"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.password,
                                    expression: "form.password",
                                  },
                                ],
                                ref: "password",
                                staticClass: "input-field px-3",
                                staticStyle: { height: "40px" },
                                attrs: {
                                  "append-icon": _vm.showPassword
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  required: "",
                                  placeholder: "",
                                  rules: _vm.rules,
                                  type: "radio",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.form.password, null),
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.fetch.apply(null, arguments)
                                  },
                                  change: function ($event) {
                                    return _vm.$set(_vm.form, "password", null)
                                  },
                                },
                              })
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.password,
                                    expression: "form.password",
                                  },
                                ],
                                ref: "password",
                                staticClass: "input-field px-3",
                                staticStyle: { height: "40px" },
                                attrs: {
                                  "append-icon": _vm.showPassword
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  required: "",
                                  placeholder: "",
                                  rules: _vm.rules,
                                  type: _vm.showPassword ? "text" : "password",
                                },
                                domProps: { value: _vm.form.password },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.fetch.apply(null, arguments)
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "password",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c("v-hover", {
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var hover = ref.hover
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-center mt-3 mb-6",
                              staticStyle: { width: "100%" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    " white--text elevation-0 btn-login ",
                                  staticStyle: {
                                    "{font-size": "60px",
                                    width: "80%",
                                    "border-radius": "20px}",
                                  },
                                  attrs: {
                                    flat: "",
                                    height: "45px",
                                    type: "submit",
                                    loading: _vm.api.isLoading,
                                    color: hover ? "primary" : "#33BDF1",
                                  },
                                  on: { click: _vm.fetch },
                                },
                                [_vm._v(" Login ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }